import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';

import { ConfirmDialogData } from '../data';

@Component({
  selector: 'mspot-confirm-cancel-dialog',
  templateUrl: './confirm-cancel-dialog.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
})
export class ConfirmCancelDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {}

  close() {
    this.dialogRef.close(true);
  }
}
