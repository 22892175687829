<ng-container *transloco="let t">
  <h1 class="heading-new">{{ t(data.title) }}</h1>
  <div class="mt-2 text-sm text-gray-500">
    <div>
      {{ t(data.message, data.messageVars || {}) }}
    </div>
    @if (data.message2) {
      <div>
        {{ t(data.message2) }}
      </div>
    }
  </div>
  <div class="mt-4 flex w-full flex-col justify-end gap-3 sm:flex-row">
    <button class="btn btn-white" (click)="dialogRef.close()">
      {{ t(data.abortText || 'common.abort') }}
    </button>
    <button class="btn btn-red" (click)="close()" cdkFocusInitial>
      {{ t(data.confirmText || 'common.confirm') }}
    </button>
  </div>
</ng-container>
