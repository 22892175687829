<ng-container *transloco="let t">
  <h1 class="heading-new mb-2 text-center">{{ t(data.title) }}</h1>
  <div class="text-sm text-gray-500">
    <div class="text-center">
      {{ t(data.message, data.messageVars || {}) }}
    </div>
    @if (data.message2) {
      <div class="text-center">
        {{ t(data.message2) }}
      </div>
    }
  </div>
  <div class="mt-6 flex w-full flex-col gap-3 sm:flex-row">
    <button class="btn btn-gray w-full" (click)="dialogRef.close()">
      {{ t(data.abortText || 'common.abort') }}
    </button>
    <button
      class="btn btn-primary w-full"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      {{ t(data.confirmText || 'common.confirm') }}
    </button>
  </div>
</ng-container>
