import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import {
  AutoFocusTarget,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { auditTime, filter, map, Observable, of, switchMap } from 'rxjs';

import { ConfirmCancelDialogComponent } from './confirm-cancel-dialog/confirm-cancel-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from './data';
import { DialogSize } from './dialog-size';

@Injectable({
  providedIn: 'root',
})
export class DialogBaseService {
  constructor(public dialog: MatDialog) {}

  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    size: DialogSize = DialogSize.SIZE_SM,
    data?: D,
    autoFocus: AutoFocusTarget | string | true = 'first-tabbable',
    filterData = true,
    addConfig?: MatDialogConfig,
  ): Observable<R> {
    return of(null).pipe(
      auditTime(0),
      switchMap(() =>
        this._open(
          componentOrTemplateRef,
          size,
          data,
          autoFocus,
          filterData,
          addConfig,
        ),
      ),
    );
  }

  public confirm(
    title: string,
    message: string,
    filterData = true,
    abortText?: string,
    confirmText?: string,
    messageVars?: Record<string, string>,
  ): Observable<boolean> {
    return this.open(
      ConfirmDialogComponent,
      DialogSize.SIZE_384,
      {
        title,
        message,
        messageVars,
        abortText,
        confirmText,
      },
      'first-tabbable',
      filterData,
    );
  }

  public confirmNew(
    data: ConfirmDialogData,
    filterData = true,
  ): Observable<boolean> {
    return this.open<ConfirmDialogComponent, ConfirmDialogData>(
      ConfirmDialogComponent,
      DialogSize.SIZE_384,
      data,
      'first-tabbable',
      filterData,
    );
  }

  public confirmCancel(
    data: ConfirmDialogData,
    filterData = true,
  ): Observable<boolean> {
    return this.open(
      ConfirmCancelDialogComponent,
      DialogSize.SIZE_512,
      data,
      'first-tabbable',
      filterData,
    );
  }

  public unsavedChanges(filterData = true) {
    return this.confirm(
      'dialog.abortChangesTitle',
      'dialog.abortChangesText',
      filterData,
      '',
      'text.discard',
    ).pipe(map((resp) => !!resp));
  }

  private _open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    size: DialogSize = DialogSize.SIZE_SM,
    data?: D,
    autoFocus: AutoFocusTarget | string | true = 'first-tabbable',
    filterData = true,
    addConfig?: MatDialogConfig,
  ): Observable<R> {
    const dialogRef = this.dialog.open(componentOrTemplateRef, {
      panelClass: size,
      data,
      disableClose: true,
      autoFocus: autoFocus === true ? 'first-tabbable' : autoFocus,
      ...addConfig,
    });

    return dialogRef
      .afterClosed()
      .pipe(
        filter((result: R) =>
          filterData ? result !== undefined && (result as any) !== '' : true,
        ),
      );
  }
}
