export enum DialogSize {
  SIZE_SM = 'dialog-sm',
  SIZE_384 = 'dialog-384',
  SIZE_420 = 'dialog-420',
  SIZE_500 = 'dialog-500',
  SIZE_512 = 'dialog-512',
  SIZE_784 = 'dialog-784',
  SIZE_MD = 'dialog-md',
  SIZE_SMALL_LG = 'dialog-small-lg',
  SIZE_SMALL_LG_VISIBLE = 'dialog-small-lg-visible',
  SIZE_LG = 'dialog-lg',
  SIZE_FULL = 'dialog-full',
}
